/* eslint-disable import/no-anonymous-default-export */
import common from './common.json';
import course from './course.json';
import errorMessage from './error_message.json';
import menu from './menu.json';
import student from './student.json';
import admin from './admin.json';

export default {
	common,
	course,
	error_message: errorMessage,
	menu,
	student,
	admin,
};
